/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill'

// Import all the third party stuff
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import FontFaceObserver from 'fontfaceobserver'
import history from 'utils/history'
import 'sanitize.css/sanitize.css'
import { ApolloProvider } from '@apollo/client'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enInLocale from 'date-fns/locale/en-IN'
import jwt from 'jsonwebtoken'

// Import root app
import App from 'containers/App/index'
// import { ThemeProvider } from 'emotion-theming'
// import { theme } from 'hoc/withResponsive'

import {
  createTheme, ThemeProvider, StyledEngineProvider,
} from '@mui/material/styles'
// import { CssBaseline } from '@mui/material'
// import { StylesProvider } from '@mui/styles'
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico' // eslint-disable-line import/no-webpack-loader-syntax
import 'file-loader?name=.htaccess!./.htaccess' // eslint-disable-line import/no-webpack-loader-syntax

import CssBaseline from '@mui/material/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'components/SnackbarUtilsConfigurator'
import ErrorBoundary from 'components/ErrorBoundary'
import { eraseCookie, getCookie, setCookieInLocalstorage } from 'utils/cookie'
import configureStore from './configureStore'

// Import i18n messages
import { translationMessages } from './i18n'
import client from './graphql/client'

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Roboto', {})

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
})

// Create redux store with history
const initialState = {}
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('app')
const root = createRoot(MOUNT_NODE)

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#2966a0' },
    secondary: { main: '#42B0AC' },
    error: { main: '#b00020' },
    text: {
      primary: 'rgba(0, 0, 0, 0.64)',
    },
    background: {
      default: '#eff3f6',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        MenuProps: {
          // to align the dropdown menu below the select component
          // https://github.com/mui-org/material-ui/issues/5634#issuecomment-393299409
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // Ripples take time, disable them to make reactions to click faster instead of waiting for the ripple to complete
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiCard: {
      defaultProps: {
        raised: false,
        elevation: 0,
      },
    },
  },
})

const token = getCookie('zykrr')
if (token) {
  const parsedToken = jwt.decode(token) as { exp: number }
  const now = new Date()
  now.setHours(now.getHours() + 3) // setting default expiry to 3 hours
  if (parsedToken?.exp) setCookieInLocalstorage('zykrr', token, new Date(parsedToken.exp * 1000))
  else setCookieInLocalstorage('zykrr', token, now)
  eraseCookie('zykrr')
}

const render = (messages) => {
  root.render(
    <Provider store={store as any}>
      {/* @ts-ignore */}
      <StyledEngineProvider injectFirst>
        <ApolloProvider client={client}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                  <SnackbarUtilsConfigurator />
                  <CssBaseline />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* locale={enInLocale} */}
                    <ErrorBoundary>
                      <App />
                    </ErrorBoundary>
                  </LocalizationProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </ConnectedRouter>
          </LanguageProvider>
        </ApolloProvider>
      </StyledEngineProvider>
    </Provider>,
  )
}

declare const module: any
if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    root.unmount()
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en.js'),
      import('intl/locale-data/jsonp/de.js'),
      import('intl/locale-data/jsonp/vi.js'),
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err
    })
} else {
  render(translationMessages)
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   const runtime = require('offline-plugin/runtime') // eslint-disable-line global-require
//   runtime.install({
//     onUpdating: () => {
//       console.log('SW Event:', 'onUpdating')
//     },
//     onUpdateReady: () => {
//       console.log('SW Event:', 'onUpdateReady')
//       // Tells to new SW to take control immediately
//       runtime.applyUpdate()
//     },
//     onUpdated: () => {
//       console.log('SW Event:', 'onUpdated')
//       // Reload the webpage to load into the new version
//       window.location.reload()
//     },
//     onUpdateFailed: () => {
//       console.log('SW Event:', 'onUpdateFailed')
//     },
//   })
// }
