import React, { useState, useEffect } from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import Skeleton from '@mui/lab/Skeleton'
import { formateMessage } from 'containers/Insight/utils'
import Wrapper from './Wrapper'

interface IOuterProps {
  allTranslations: any
}

const LoadingIndicator: React.FunctionComponent<any & IOuterProps> = (props: IOuterProps) => (
  <>
    <Skeleton variant="rectangular" height={80} width="100%" />
    <Skeleton variant="rectangular" height={80} width="100%" className="my-1" />
    <Skeleton variant="rectangular" height={80} width="100%" />
    <p style={{ paddingTop: '20px', fontSize: '16px', textAlign: 'center' }}>
      {formateMessage(props?.allTranslations, 'Preparing data for you. Please wait', 'Preparing data for you. Please wait')}
      ..
      {' '}
    </p>
  </>
)

export default LoadingIndicator
