/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'

import Insight from 'containers/Insight/Loadable'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import Dashboard from 'containers/Dashboard/Loadable'
import Footer from 'components/Footer'
import OfflineNotifier from 'components/OfflineNotifier'
import footerStyle from '../../components/Footer/style.css'
import Pages from './pages'
import EmailSubscription from './EmailSubscription/Loadable'
import ReportDownload from './ReportDownload/Loadable'
import Subscriptions from './Subscriptions/Loadable'
import GlobalStyle from '../../global-styles'
import {
  getCookieFromLocalstorage,
} from '../../utils/cookie'

const PrivateRoute = (props) => {
  const loggedIn = getCookieFromLocalstorage('zykrr')

  if (loggedIn) return <Route {...props} />

  // redirect to auth service for login
  window.location.href = `${process.env.AUTH_HOST}/sign_in?continue=${encodeURIComponent(
    window.location.href,
  )}`

  return null
}

export default function App() {
  return (
    <>
      <Helmet titleTemplate="%s - Zykrr Insights" defaultTitle="Zykrr">
        <meta name="description" content="Zykrr website" />
      </Helmet>
      <OfflineNotifier />
      <div className={footerStyle.footerContainer}>
        <Switch>
          <PrivateRoute exact path={Pages.dashboard} component={Dashboard} />
          <PrivateRoute path={Pages.analytics} component={Insight} />
          <PrivateRoute path={Pages.emailNotification} component={EmailSubscription} />
          <PrivateRoute path={Pages.downloadReport} component={ReportDownload} />
          <PrivateRoute path={Pages.subscriptions} component={Subscriptions} />
          <Route component={NotFoundPage} />
        </Switch>
        <GlobalStyle />
        <Footer />
      </div>
    </>
  )
}
